import React from 'react';
import TywMiniLogo from '../svg/TywMiniLogo';
import Link from 'next/link';
import { Divider } from 'day8-ui';
import { FaFacebook, FaInstagram, FaTiktok, FaYoutube } from "react-icons/fa";

type FooterConfig = {
  label: string
  href?: string
  links?: {
    label: string
    href: string
    target?: string
  }[]
}[]

const Footer = ({ footerConfig }: { footerConfig: FooterConfig }) => {
  return (
    <div className='dui-bg-primary-950 dui-text-white'>
      <div className='dui-container dui-mx-auto dui-py-4'>
        {/* <div className='dui-flex dui-flex-col md:dui-flex-row dui-justify-between dui-gap-4'> */}
        <div className='dui-flex dui-flex-col md:dui-flex-row dui-gap-8'>
          <div className='dui-w-full md:dui-w-1/3 lg:dui-w-2/3'>
            <Link href="/">
              <TywMiniLogo className='dui-w-[64px] dui-h-[64px]' fill="#ffffff" />
            </Link>
            <p className='dui-mt-4'>
              Yacht Week brand and concept is owned by Day 8 AB - a private limited company registered in Stockholm, Sweden. Day 8 Experiences Ltd acts as a travel agent on the behalf of Yacht Week.
            </p>
          </div>
          <div className='dui-grid dui-grid-cols-2 sm:dui-flex sm:dui-justify-between dui-w-full dui-gap-4'>
            {footerConfig?.map(item => (
              <div className='dui-flex dui-flex-col dui-gap-4'>
                {!!item.href && <Link href={item.href} className='dui-mb-4'><span className='dui-uppercase dui-font-normal' >{item.label}</span></Link>}
                {!item.href && <div className='dui-mb-4'><span className='dui-uppercase dui-font-normal' >{item.label}</span></div>}
                {item.links?.map(link => (
                  <>
                    {link.target && link.target === '_blank' ? <a href={link.href} target={link.target} rel="noopener noreferrer" className='dui-font-normal' >{link.label}</a> : <Link href={link.href} className='dui-font-normal' >{link.label}</Link>}
                  </>
                ))}
              </div>
            ))}
          </div>
        </div>
        <Divider className='dui-my-8' />
        <div className='dui-flex dui-items-center dui-justify-between'>
          <div>
            <p>© {new Date().getFullYear()} Yacht week. All rights reserved.</p>
          </div>
          <div className='dui-flex dui-gap-4'>
            <a href="https://www.facebook.com/theyachtweek" target='_blank' rel="noopener noreferrer">
              <FaFacebook className='dui-w-[22px] dui-h-[22px]' />
            </a>
            <a href="https://www.instagram.com/theyachtweek/" target='_blank' rel="noopener noreferrer">
              <FaInstagram className='dui-w-[22px] dui-h-[22px]' />
            </a>
            <a href="https://www.tiktok.com/@theyachtweek" target='_blank' rel="noopener noreferrer">
              <FaTiktok className='dui-w-[22px] dui-h-[22px]' />
            </a>
            <a href="https://www.youtube.com/user/TheYachtWeek" target='_blank' rel="noopener noreferrer">
              <FaYoutube className='dui-w-[22px] dui-h-[22px]' />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
};

export default Footer;