import React, { useEffect, useContext } from 'react';
import useSWR from 'swr'
import Router from 'next/router';
import NProgress from 'nprogress';
import Head from 'next/head';
import { NotificationProvider } from 'day8-react/build/new-components';
import { GlobalContext, GlobalProvider } from '../context/GlobalState';
import { WpPageType } from '../globalTypes';
import Navbar from '../components/navbar/Navbar'
import { getNavbarConfig } from '../helpers/api';
import Footer from '../components/footer/Footer';

// const Navbar = dynamic(() => import('day8-react/build/new-components').then(c => c.Navbar));
// const Footer = dynamic(() => import('day8-react/build/new-components').then(c => c.Footer));

const DEFAULT_TITLE = 'Yacht Week';
const DEFAULT_DESCRIPTION = 'A floating festival staged in 5 hand-picked destinations around the world.';
const DEFAULT_IMAGE = 'https://cdn.theyachtweek.com/assets/public/img/base/start/circle-raft-facebook.jpg';

type Layout = {
  page: WpPageType
  children: React.ReactNode
  hiddenCurrency?: boolean
  signupHidden?: boolean
  className?: string
  isFooterHidden?: boolean
  isNavbarTransparent?: boolean
  isNavbarFixed?: boolean
  isNavbarHidden?: boolean
  footerTemplate?: 'DefaultFooter' | 'LiteFooter' | 'SeaMythFooter' | 'SOLFooter' // this should be probably in day8-react as FooterType thing or something
  isCookieBannerHidden?: boolean
  supportedCurrencies?: string[] // this should be probably in day8-react as FooterType thing or something
  socialLinks?: {
    link: string
    icon: string
  }[]
  canonical?: string
}

const Layout: React.FC<Layout> = ({
  page,
  children,
  hiddenCurrency,
  className,
  isFooterHidden,
  isNavbarTransparent,
  isNavbarHidden,
  isNavbarFixed,
  canonical
}) => {
  const { data } = useSWR('/api/get-navbar-config', getNavbarConfig);
  const navbarConfig = data?.navbar_group || [];
  const footerConfig = data?.footer_group || [];
  useEffect(() => {
    const handleRouteStart = () => NProgress.start();
    const handleRouteDone = () => {
      NProgress.done();
    };
    Router.events.on('routeChangeStart', handleRouteStart);
    Router.events.on('routeChangeComplete', handleRouteDone);
    Router.events.on('routeChangeError', handleRouteDone);
    return () => {
      // Make sure to remove the event handler on unmount!
      Router.events.off('routeChangeStart', handleRouteStart);
      Router.events.off('routeChangeComplete', handleRouteDone);
      Router.events.off('routeChangeError', handleRouteDone);
    };
  }, []);
  const globalContext = useContext(GlobalContext);
  const { toggleShowCookieBanner, showCookieBanner } = globalContext;
  const { yoast } = page;
  const title = (yoast && yoast.title) || page.seo_title || (page.title && page.title.rendered) || DEFAULT_TITLE;
  const ogDescription = yoast ? yoast.opengraph_description : null;
  const description = (yoast && yoast.metadesc) || page.seo_description || DEFAULT_DESCRIPTION;
  let image = DEFAULT_IMAGE;
  if (yoast && yoast.opengraph_image) {
    image = yoast.opengraph_image;
  } else if (page.featured_image_src && !page.featured_image_src.includes('media/default.png')) {
    image = page.featured_image_src;
  }
  let currencyHidden = false;
  if (page.acf && page.acf.hide_currency) {
    currencyHidden = page.acf.hide_currency;
  } else if (hiddenCurrency) {
    currencyHidden = hiddenCurrency;
  }
  return (
    <NotificationProvider>
      <GlobalProvider>
        <Head>
          <title>{title}</title>
          <meta name="description" content={description} />
          <meta key="og:type" property="og:type" content="website" />
          <meta key="og:image" property="og:image" content={image} />
          <meta key="og:title" property="og:title" content={title} />
          <meta key="og:description" property="og:description" content={ogDescription || description} />
          <meta name="theme-color" content="#182c4b" />
          <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
          {canonical && <link rel="canonical" href={canonical} />}
        </Head>
        {!isNavbarHidden && <Navbar currencyHidden={currencyHidden} isNavbarTransparent={isNavbarTransparent} isNavbarFixed={isNavbarFixed}
          navbarConfig={navbarConfig}
        />}
        <div className={className}>
          {children}
          <Footer footerConfig={isFooterHidden ? [] : footerConfig} />
        </div>
      </GlobalProvider>
    </NotificationProvider>
  );
};

export default Layout;
